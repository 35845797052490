<template>
  <div>
    <header class="mint-header" :class="{ 'is-fixed': fixed }" style="background-color: #67b835;">
      <div class="mint-header-button is-left">
        <slot name="left"></slot>
      </div>
      <h1 v-show='title' class="mint-header-title" v-text="title"></h1>
      <div class="mint-header-button is-right">
        <slot name="right"></slot>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'PhonePageHeader',
  props: {
    fixed: Boolean,
    title: String,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style lang="css" scope>
@component-namespace mint {
  @component header {
    align-items: center;
    background-color: #67b835;
    box-sizing: border-box;
    color: $color-white;
    display: flex;
    font-size: 0.28rem;
    height: $header-height;
    line-height: 1;
    padding: 0 0.2rem;
    position: relative;
    text-align: center;
    white-space: nowrap;
    .mint-button {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      color: inherit;
      display: inline-block;
      padding: 0;
      font-size: inherit;
      &::after {
        content: none;
      }
    }
    @descendent button {
      flex: 0.5;
      > a {
        color: inherit;
      }
      @when left {
        text-align: left;
      }
      @when right {
        text-align: right;
      }
    }
    @descendent title {
      @utils-ellipsis;
      font-size: inherit;
      font-weight: normal;
      flex: 1;
    }
    @when fixed {
      position: fixed 0 0 * 0;
      z-index: $z-index-normal;
    }
  }
}
.mint-header-title {
  font-size: .45rem;
}
.mint-header-title img {
  max-height: 0.6rem;
}
.mint-header.is-fixed {
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 2;
}
</style>
