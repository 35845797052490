<template>
  <div>
    <header class="mint-header" :class="{ 'is-fixed': fixed }">
      <div class="mint-header-button is-left">
        <slot name="left"></slot>
      </div>
      <h1 v-show='title' class="mint-header-title" v-text="title"></h1>
      <div v-show='!title' class="mint-header-title"><a href="/"><h1><img src="static/images/s-logo.png" alt="荟乐教育logo"></h1></a></div>
      <div class="mint-header-button is-right">
        <slot name="right"></slot>
        <a @click='showMore'>
          <img src="static/images/more.png" style="height: 0.41rem;" />
        </a>
      </div>
    </header>
    <VueSlideoutPanel ref='pannel' style="width:50%;" :value.sync='this.VueSlideoutPanelValue'>
      <el-menu router :default-active='$route.path'>

    <section class="panel default" style="width: 100%;
    position: relative;
    display: inline-block;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #eef1f6;">
      <div style="height: 100%;overflow: auto;">
        <ul class="el-menu">
          <a href="/news?cid=5&amp;pageName=企业资讯">
            <li class="el-submenu">
              <div class="el-submenu__title" style="padding-left: 20px;font-size: 16px;"><img src="static/menu/0.png" style="height: 0.8rem;padding-right: 5px;">企业资讯<i class="el-submenu__icon-arrow el-icon-arrow-right"></i></div>
            </li>
          </a>
          <a href="/news?cid=6&amp;pageName=行业信息">
            <li class="el-submenu">
              <div class="el-submenu__title" style="padding-left: 20px;font-size: 16px;"><img src="static/menu/1.png" style="height: 0.8rem;padding-right: 5px;">行业信息<i class="el-submenu__icon-arrow el-icon-arrow-right"></i></div>
            </li>
          </a>
          <a href="/about?pageName=走进荟乐">
            <li class="el-submenu">
              <div class="el-submenu__title" style="padding-left: 20px;font-size: 16px;"><img src="static/menu/2.png" style="height: 0.8rem;padding-right: 5px;">关于荟乐<i class="el-submenu__icon-arrow el-icon-arrow-right"></i></div>
            </li>
          </a>
          <a href="/job?pageName=招聘机会">
            <li class="el-submenu">
              <div class="el-submenu__title" style="padding-left: 20px;font-size: 16px;"><img src="static/menu/3.png" style="height: 0.8rem;padding-right: 5px;">招聘机会<i class="el-submenu__icon-arrow el-icon-arrow-right"></i></div>
            </li>
          </a>
          <a href="/message?pageName=在线留言">
            <li class="el-submenu">
              <div class="el-submenu__title" style="padding-left: 20px;font-size: 16px;"><img src="static/menu/5.png" style="height: 0.8rem;padding-right: 5px;">在线留言<i class="el-submenu__icon-arrow el-icon-arrow-right"></i></div>
            </li>
          </a>
          <a href="/about?infoId=154&pageName=版权声明">
            <li class="el-submenu">
              <div class="el-submenu__title" style="padding-left: 20px;font-size: 16px;"><img src="static/menu/4.png" style="height: 0.8rem;padding-right: 5px;">版权声明<i class="el-submenu__icon-arrow el-icon-arrow-right"></i></div>
            </li>
          </a>
          <!-- <a href="javascript:void(0)" onclick="_MEIQIA('showPanel')">
            <li class="el-submenu">
              <div class="el-submenu__title" style="padding-left: 20px;font-size: 16px;"><img src="static/menu/6.png" style="height: 0.8rem;padding-right: 5px;">在线客服<i class="el-submenu__icon-arrow el-icon-arrow-right"></i></div>
            </li>
          </a> -->
        </ul>
      </div>
    </section>
      </el-menu>
    </VueSlideoutPanel>

  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    fixed: Boolean,
    title: String,
  },
  data() {
    return {
      VueSlideoutPanelValue: false,
      selected: '1',
      bottomMenuGroups: [],
    };
  },
  methods: {
    link() {
     // _MEIQIA('showPanel'); // eslint-disable-line
    },
    getLink(it) {
      return it.linkurl + (it.linkurl.lastIndexOf("?") > -1 ? "&" : "?") + "pageName=" + it.classname; // eslint-disable-line
    },
    showMore() {
      if (this.VueSlideoutPanelValue) {
        this.$refs.pannel.isVisibleDock = true;
      } else {
        this.VueSlideoutPanelValue = true;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$http.post('api/nav/list').then((rep) => {
        if (rep.data) {
          this.bottomMenuGroups = rep.data.bottomMenuGroups;
        }
      });
    });
  },
};
</script>

<style lang="css" scope>
@component-namespace mint {
  @component header {
    align-items: center;
    background-color: $color-blue;
    box-sizing: border-box;
    color: $color-white;
    display: flex;
    font-size: 0.28rem;
    height: $header-height;
    line-height: 1;
    padding: 0 0.2rem;
    position: relative;
    text-align: center;
    white-space: nowrap;
    .mint-button {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      color: inherit;
      display: inline-block;
      padding: 0;
      font-size: inherit;
      &::after {
        content: none;
      }
    }
    @descendent button {
      flex: 0.5;
      > a {
        color: inherit;
      }
      @when left {
        text-align: left;
      }
      @when right {
        text-align: right;
      }
    }
    @descendent title {
      @utils-ellipsis;
      font-size: inherit;
      font-weight: normal;
      flex: 1;
    }
    @when fixed {
      position: fixed 0 0 * 0;
      z-index: $z-index-normal;
    }
  }
}
.mint-header-title {
  font-size: .45rem;
}
.mint-header-title img {
  max-height: 0.6rem;
}
.mint-header.is-fixed {
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 2;
}
.mintui {
  font-size: 0.6rem;
}
</style>
