import Vant from 'vant';
import 'vant/lib/index.css';
import Vue from 'vue';
import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';
import VueResource from 'vue-resource';
import MintUI from 'mint-ui';
import ElementUI from 'element-ui';
import FastClick from 'fastclick';
import Checkbox from 'vue-material-checkbox';
import 'element-ui/lib/theme-default/index.css';
import 'font-awesome/css/font-awesome.css';
import 'mint-ui/lib/style.css';
import './css/swiper-3.4.2.min.css';
import './css/reset.css';
import './css/global.css';
import router from './router';
import VueSlideoutPanel from './pages/components/VueSlideoutPanel';
import PageFooter from './pages/components/PageFooter';
import PageHeader from './pages/components/PageHeader';
import PhonePageHeader from './pages/components/PhonePageHeader';
import CouponItem from './pages/components/CouponItem';

Vue.use(Vant);

// 解决web端 TypeError: t.$t is not a function 报错
Vue.use(VueResource);
Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        i18n: vuexI18n.store
    }
});
Vue.use(vuexI18n.plugin, store);
const translationsEn = {
    "content": "This is some {type} content"
};
Vue.i18n.add('en', translationsEn);
Vue.i18n.set('en');

Vue.config.productionTip = false;

Vue.use(MintUI);
Vue.use(ElementUI);
Vue.component('VueSlideoutPanel', VueSlideoutPanel);
Vue.use(VueSlideoutPanel);
Vue.component('PageFooter', PageFooter);
Vue.use(PageFooter);
Vue.component('PageHeader', PageHeader);
Vue.use(PageHeader);
Vue.component('PhonePageHeader', PhonePageHeader);
Vue.use(PhonePageHeader);
Vue.use(CouponItem);
Vue.component('CouponItem', CouponItem);
Vue.use(Checkbox);
Vue.use(VueResource);

// Vue.http.options.root = 'api';
Vue.prototype.ScrollTop = () => {
  document.getElementsByClassName("mint-tab-container-wrap")[0].scrollIntoView();
};

const app = new Vue({
  router,
});

app.$mount('#app');
Vue.http.interceptors.push((request, next) => {
  if (window.localStorage.getItem('token')) {
    Vue.http.headers.common.token = window.localStorage.getItem('token');
  }
  next((response) => {
    // if (response.data.code === 401) {
    //   if (response.data.msg === 'token失效，请重新登录' || response.data.msg === 'token不能为空') {
    //     Vue.$toast('需要您登录');
    //     window.location.href = '/login?historyUrl=' + window.location.href; // eslint-disable-line
    //   } else {
    //     Vue.$toast(response.data.msg);
    //     window.location.href = '/login?historyUrl=' + window.location.href; // eslint-disable-line
    //   }
    // }
    if (response.status === 400) {
      MintUI.Toast({
        message: response.data.msg,
        duration: 2000,
      });
    }
  });
});
function NoClickDelay(el) {
  // eslint-disable-next-line eqeqeq
  this.element = typeof el === 'object' ? el : document.getElementById(el);
  if (window.Touch) this.element.addEventListener('touchstart', this, false);
}
NoClickDelay.prototype = {
  // eslint-disable-next-line object-shorthand,func-names
  handleEvent: function (e) {
    // eslint-disable-next-line default-case
    switch (e.type) {
      case 'touchstart':
        this.onTouchStart(e);
        break;
      case 'touchmove':
        this.onTouchMove(e);
        break;
      case 'touchend':
        this.onTouchEnd(e);
        break;
    }
  },
  // eslint-disable-next-line no-unused-vars,object-shorthand,func-names
  onTouchStart: function (e) {
    e.preventDefault(); this.moved = false;
    // eslint-disable-next-line max-len
    this.theTarget = document.elementFromPoint(e.targetTouches[0].clientX, e.targetTouches[0].clientY);
    if (this.theTarget.nodeType === 3) {
      this.theTarget = this.theTarget.parentNode;
    }
    this.theTarget.className += ' pressed';
    this.element.addEventListener('touchmove', this, false);
    this.element.addEventListener('touchend', this, false);
  },
  // eslint-disable-next-line no-unused-vars,object-shorthand,func-names
  onTouchMove: function (e) {
    this.moved = true;
    this.theTarget.className = this.theTarget.className.replace(/ ?pressed/gi, '');
  },
  // eslint-disable-next-line no-unused-vars,object-shorthand,func-names
  onTouchEnd: function (e) {
    this.element.removeEventListener('touchmove', this, false);
    this.element.removeEventListener('touchend', this, false);
    if (!this.moved && this.theTarget) {
      this.theTarget.className = this.theTarget.className.replace(/ ?pressed/gi, '');
      const theEvent = document.createEvent('MouseEvents');
      theEvent.initEvent('click', true, true);
      this.theTarget.dispatchEvent(theEvent);
    }
    this.theTarget = undefined;
  },
};
window.addEventListener('load', () => {
  FastClick.attach(document.body);
});
