/* eslint-disable global-require */

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'app',
      component: (resolve) => require(['@/pages/Index'], resolve), // eslint-disable-line
    },
    {
      path: '/index.html',
      name: 'app',
      component: (resolve) => require(['@/pages/Index'], resolve), // eslint-disable-line
    },
    {
      path: '/content',
      name: 'content',
      component: (resolve) => require(['@/pages/about/About'], resolve), // eslint-disable-line
    },
    {
      path: '/about',
      name: 'about',
      component: (resolve) => require(['@/pages/about/About'], resolve), // eslint-disable-line
    },
    {
      path: '/job',
      name: 'job',
      component: (resolve) => require(['@/pages/about/Job'], resolve), // eslint-disable-line
    },
    {
      path: '/assistant',
      name: 'assistant',
      component: (resolve) => require(['@/pages/assistant/Assistant'], resolve), // eslint-disable-line
    },
    {
      path: '/jobapply',
      name: 'jobapply',
      component: (resolve) => require(['@/pages/about/JobApply'], resolve), // eslint-disable-line
    },
    {
      path: '/mycoupon',
      name: 'mycoupon',
      component: (resolve) => require(['@/pages/coupon/MyCoupon'], resolve), // eslint-disable-line
    },
    {
      path: '/coupondetail',
      name: 'coupondetail',
      component: (resolve) => require(['@/pages/coupon/CouponDetail'], resolve), // eslint-disable-line
    },
    {
      path: '/couponshare',
      name: 'couponshare',
      component: (resolve) => require(['@/pages/coupon/CouponShare'], resolve), // eslint-disable-line
    },
    {
      path: '/mysale',
      name: 'mysale',
      component: (resolve) => require(['@/pages/mine/mySale'], resolve), // eslint-disable-line
    },
    {
      path: '/mysaledetail',
      name: 'mysaledetail',
      component: (resolve) => require(['@/pages/mine/mySaleDetail'], resolve), // eslint-disable-line
    },
    {
      path: '/mymember',
      name: 'mymember',
      component: (resolve) => require(['@/pages/mine/myMember'], resolve), // eslint-disable-line
    },
    {
      path: '/mygoods', // 自营商品
      name: 'mygoods',
      component: (resolve) => require(['@/pages/mine/goods/list'], resolve), // eslint-disable-line
    },
    {
      path: '/mygoodsdetail', // 自营商品详情
      name: 'mygoodsdetail',
      component: (resolve) => require(['@/pages/mine/goods/detail'], resolve), // eslint-disable-line
    },
    {
      path: '/mygoodsnew', // 新增自营商品
      name: 'mygoodsnew',
      component: (resolve) => require(['@/pages/mine/goods/new'], resolve), // eslint-disable-line
    },
    {
      path: '/mybill', // 我的账单
      name: 'mybill',
      component: (resolve) => require(['@/pages/mine/myBill'], resolve), // eslint-disable-line
    },
    {
      path: '/mybilllist', // 我的账单列表
      name: 'mybilllist',
      component: (resolve) => require(['@/pages/mine/myBillList'], resolve), // eslint-disable-line
    },
    {
      path: '/mybilldetail', // 我的账单详情
      name: 'mybilldetail',
      component: (resolve) => require(['@/pages/mine/myBillDetail'], resolve), // eslint-disable-line
    },
    {
      path: '/mybillapply', // 我的账单申请
      name: 'mybillapply',
      component: (resolve) => require(['@/pages/mine/myBillApply'], resolve), // eslint-disable-line
    },
    {
      path: '/createmember',
      name: 'createmember',
      component: (resolve) => require(['@/pages/mine/createMember'], resolve), // eslint-disable-line
    },
    {
      path: '/returnOrder',
      name: 'returnOrder',
      component: (resolve) => require(['@/pages/mine/ReturnOrder'], resolve), // eslint-disable-line
    },
    {
      path: '/myvipcoupon',
      name: 'myvipcoupon',
      component: (resolve) => require(['@/pages/coupon/MyVipCoupon'], resolve), // eslint-disable-line
    },
    {
      path: '/myvipcoupondetail',
      name: 'myvipcoupondetail',
      component: (resolve) => require(['@/pages/coupon/MyVipCouponDetail'], resolve), // eslint-disable-line
    },
    {
      path: '/createcoupon',
      name: 'createcoupon',
      component: (resolve) => require(['@/pages/coupon/CreateCoupon'], resolve), // eslint-disable-line
    },
    {
      path: '/couponlist',
      name: 'couponlist',
      component: (resolve) => require(['@/pages/coupon/CouponList'], resolve), // eslint-disable-line
    },

    {
      path: '/shopping',
      name: 'shopping',
      component: (resolve) => require(['@/pages/shopping/Shopping'], resolve), // eslint-disable-line
    },
    {
      path: '/shoppingcategorylist',
      name: 'shoppingcategorylist',
      component: (resolve) => require(['@/pages/shopping/ShoppingCategoryList'], resolve), // eslint-disable-line
    },
    {
      path: '/shoppinglist',
      name: 'shoppinglist',
      component: (resolve) => require(['@/pages/shopping/ShoppingList'], resolve), // eslint-disable-line
    },
    {
      path: '/shoppingdetail',
      name: 'shoppingdetail',
      component: (resolve) => require(['@/pages/shopping/ShoppingDetail'], resolve), // eslint-disable-line
    },
    {
      path: '/shoppingcart',
      name: 'shoppingcart',
      component: (resolve) => require(['@/pages/shopping/ShoppingCart'], resolve), // eslint-disable-line
    },
    {
      path: '/addressmanage',
      name: 'addressmanage',
      component: (resolve) => require(['@/pages/address/Address'], resolve), // eslint-disable-line
    },
    {
      path: '/addresslist',
      name: 'addresslist',
      component: (resolve) => require(['@/pages/address/AddressList'], resolve), // eslint-disable-line
    },
    {
      path: '/mine',
      name: 'mine',
      component: (resolve) => require(['@/pages/mine/Mine'], resolve), // eslint-disable-line
    },
    {
      path: '/mysetting',
      name: 'mysetting',
      component: (resolve) => require(['@/pages/mine/MineSetting'], resolve), // eslint-disable-line
    },
    {
      path: '/pwdsetting',
      name: 'pwdsetting',
      component: (resolve) => require(['@/pages/mine/PwdSetting'], resolve), // eslint-disable-line
    },
    {
      path: '/pwdchange',
      name: 'pwdchange',
      component: (resolve) => require(['@/pages/mine/PwdChange'], resolve), // eslint-disable-line
    },
    {
      path: '/phonesetting',
      name: 'phonesetting',
      component: (resolve) => require(['@/pages/mine/PhoneSetting'], resolve), // eslint-disable-line
    },
    {
      path: '/mycenter',
      name: 'mycenter',
      component: (resolve) => require(['@/pages/mine/MyCenter'], resolve), // eslint-disable-line
    },
    {
      path: '/myorder',
      name: 'myorder',
      component: (resolve) => require(['@/pages/mine/MyOrder'], resolve), // eslint-disable-line
    },
    {
      path: '/checkorder',
      name: 'checkorder',
      component: (resolve) => require(['@/pages/order/CheckOrder'], resolve), // eslint-disable-line
    },
    {
      path: '/checkpay',
      name: 'checkpay',
      component: (resolve) => require(['@/pages/order/CheckPay'], resolve), // eslint-disable-line
    },
    {
      path: '/myinfo',
      name: 'myinfo',
      component: (resolve) => require(['@/pages/mine/MyInfo'], resolve), // eslint-disable-line
    },
    {
      path: '/login',
      name: 'login',
      component: (resolve) => require(['@/pages/mine/Login'], resolve), // eslint-disable-line
    },
    {
      path: '/wesleylandlogin',
      name: 'wesleylandlogin',
      component: (resolve) => require(['@/pages/mine/wesleylandLogin'], resolve), // eslint-disable-line
    },
    {
      path: '/register',
      name: 'register',
      component: (resolve) => require(['@/pages/mine/Register'], resolve), // eslint-disable-line
    },
    {
      path: '/order',
      name: 'order',
      component: (resolve) => require(['@/pages/order/Order'], resolve), // eslint-disable-line
    },
    {
      path: '/beforeorder',
      name: 'beforeorder',
      component: (resolve) => require(['@/pages/order/BeforeOrder'], resolve), // eslint-disable-line
    },
    {
      path: '/orderdone',
      name: 'orderdone',
      component: (resolve) => require(['@/pages/order/OrderDone'], resolve), // eslint-disable-line
    },
    {
      path: '/order2wslwg',
      name: 'order2wslwg',
      component: (resolve) => require(['@/pages/order/Order2Wslwg'], resolve), // eslint-disable-line
    },
    {
      path: '/orderdetail',
      name: 'orderdetail',
      component: (resolve) => require(['@/pages/order/OrderDetail'], resolve), // eslint-disable-line
    },
    {
      path: '/news',
      name: 'news',
      component: (resolve) => require(['@/pages/news/News'], resolve), // eslint-disable-line
    },
    {
      path: '/newsdetail',
      name: 'newsdetail',
      component: (resolve) => require(['@/pages/news/Newsdetail'], resolve), // eslint-disable-line
    },
    {
      path: '/index2',
      name: 'index2',
      component: (resolve) => require(['@/pages/Index2'], resolve), // eslint-disable-line
    },
    {
      path: '/message',
      name: 'message',
      component: (resolve) => require(['@/pages/message/Index'], resolve), // eslint-disable-line
    },
    {
      path: '/messageSave',
      name: 'messageSave',
      component: (resolve) => require(['@/pages/message/MsgSave'], resolve), // eslint-disable-line
    },
    // {newsdetail
    //   path: '/regester',
    //   name: 'regester',
    //   component: require('@/pages/Regester'),
    // },
    // {
    //   path: '/services',
    //   name: 'services',
    //   component: require('@/pages/Services'),
    // },
    // {
    //   path: '/merchantinfo',
    //   name: 'merchantinfo',
    //   component: require('@/pages/MerchantInfo'),
    // },
    // {
    //   path: '/shopcenter',
    //   name: 'shopcenter',
    //   component: require('@/pages/ShopCenter'),
    // },
    // {
    //   path: '/orderdetail',
    //   name: 'orderdetail',
    //   component: require('@/pages/Orderdetail'),
    // },
    // {
    //   path: '/userbankinfo',
    //   name: 'userbankinfo',
    //   component: require('@/pages/Userbankinfo'),
    // },
    // {
    //   path: '/userpwdma',
    //   name: 'userpwdma',
    //   component: require('@/pages/Userpwdma'),
    // },
  ],
});
