<template>
  <main>
    <div class="quan-list">
      <div class="quan-item quan-d-item" style="text-align: center;">
        <img src="static/images/yhj-d0.png" style="width: 94vw;height: 30vw;" />
        <div class="q-type">
         <div style="float: left; width: 30vw;height: 30vw;margin-left: 5vw;font-size: 4.5vw;">
          <div class="q-price">
            <strong v-show='this.$props.item.couponType !== "折扣券"'>￥</strong>
            <strong class="num" style="font-size: 7vw">{{this.$props.item.currentCoupon}}</strong>
            <strong v-show='this.$props.item.couponType === "折扣券"'>%</strong>
          </div>
          <div class="txt">
            <div class="typ-txt">{{this.$props.item.couponType}}</div>
          </div>
         </div>
         <div style="float: left;width: 60vw;margin-right: 5vw;height: 30vw;font-size: 4vw;white-space: nowrap;">
          <div style="margin-top: 3vw;">{{this.$props.item.shareRemark}}　</div> 
          <div style="margin-top: 1vw;">
            <div class="range-item" v-show='this.$props.item.couponType === "满减券"'>满{{this.$props.item.fitAmount}}元减{{this.$props.item.currentCoupon}}</div>
            <div class="range-item" v-show='this.$props.item.couponType === "折扣券"'>满{{this.$props.item.fitAmount}}元优惠{{this.$props.item.currentCoupon}}%</div>
            <div class="range-item" v-show='this.$props.item.couponType === "无门槛券"'>立减{{this.$props.item.currentCoupon}}</div>
            <!-- <div class="range-item" v-show='this.$props.item.nextCoupon'>当前优惠截止日期: {{this.$props.item.currentEndDate}}</div>
            <div class="range-item" v-show='this.$props.item.nextCoupon && this.$props.item.couponType === "满减券" && this.$props.item.nextCoupon'>下一阶段优惠: 满 {{this.$props.item.fitAmount}}元减{{this.$props.item.nextCoupon}}</div>
            <div class="range-item" v-show='this.$props.item.nextCoupon && this.$props.item.couponType === "折扣券" && this.$props.item.nextCoupon'>下一阶段优惠: 满{{this.$props.item.fitAmount}}元优惠{{this.$props.item.nextCoupon}}%</div>
            <div class="range-item" v-show='this.$props.item.nextCoupon && this.$props.item.couponType === "无门槛券" && this.$props.item.nextCoupon'>下一阶段优惠: 立减{{this.$props.item.nextCoupon}}元</div> -->
          </div>
          <div class="q-range">
            <div style="margin-top: 2.5vw;line-height: 1.2;font-size: 3.5vw;">
              {{this.$props.item.applyPostStr}}<br>
              {{this.$props.item.applyStr}}<br>
              {{this.$props.item.useRange}}
            </div>
          </div>
         </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'CouponItem',
  props: ['item'],
  methods: {
    goShoppingList(page) {
      if (page === 'shopping') {
        this.$router.push({
          name: 'shoppinglist',
          query: {
            categoryId: '1',
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.selectContainer {
  width: 30%;
  margin-right: 1%;
  margin-top: 3vw;
  margin-bottom: 3vw;
}
.optionBtn {
  margin-right: 2vw;
}
.range-item {
  color: #000;
  font-size: 4vw;
}
.typ-txt {
  color: #fff;
  font-size: 5vw;
}
@media only screen and (max-width: 767px) {
  .selectContainer {
    width: 90%;
    margin-left: 5%;
    margin-top: 3vw;
    margin-bottom: 0;
  }
  .selectContainer-last {
    margin-bottom: 3vw;
  }
}
.quan-list {
  display: inline-block;
  display: block;
  width: 100%;
}
.quan-item {
  position: relative;
  border: none;
  font-family: "Microsoft YaHei";
}
.quan-list .quan-item {
  float: left;
  margin: 2px 2vw 2.8vw 2px;
}
@media only screen and (max-width: 767px) {
  .quan-list .quan-item {
    float: none;
    margin: 1vw auto;
  }
}
.quan-item .q-type {
  position: absolute;
  top: 0;
}
.quan-item .q-price {
  color: #fff;
  padding-top: 9vw;
}
em,
i,
u {
  font-style: normal;
}
.quan-item .q-price em {
  float: left;
  margin: 2.6vw 0 0;
  font-family: verdana;
  font-size: 2.4vw;
}
a,
address,
b,
big,
blockquote,
body,
center,
cite,
code,
dd,
del,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
img,
ins,
label,
legend,
li,
ol,
p,
pre,
small,
span,
strong,
u,
ul,
var {
  margin: 0;
  padding: 0;
}
.quan-item .q-price strong {
  line-height: 5vw;
  margin: 0 1vw 0 0.5vw;
  font-size: 4vw;
  font-family: arial;
}
.quan-item .q-price .txt {
  line-height: 1.5;
  margin-top: 2vw;
}
.quan-item .limit {
  width: 25vw;
  height: 2vw;
  line-height: 2vw;
  margin-bottom: 0.5vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ftx-06,
.ftx06 {
  color: #666;
}
.quan-item .q-range {
  line-height: 2vw;
  color: #999;
}
.quan-item .q-opbtns {
  width: 2vw;
  height: 14.4vw;
  padding: 4.8vw 2vw 0;
  line-height: 1.5;
  background: #74d2d4;
  color: #fff;
  font-size: 1.4vw;
}
.quan-d-item .q-opbtns {
  background: #74d2d4;
}
.quan-item .q-opbtns {
  padding-left: 3.6vw;
  padding-right: 2.4vw;
}
a {
  text-decoration: none;
}
.quan-item .q-opbtns a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
}
.mod-main {
  padding: 1vw;
  background-color: #fff;
}
.m,
.mb,
.mc,
.mt,
.sm,
.smb,
.smc,
.smt {
  overflow: hidden;
  zoom: 1;
}
.mod-comm .mt {
  margin-bottom: 0;
  background: #fff;
  padding: 2vw 2vw 0;
}
.mod-main .mt {
  display: inline-block;
  display: block;
  padding: 1vw;
}
.mod-main .mt h3 {
  float: left;
  font-family: "Microsoft YaHei";
  font-size: 1.6vw;
  color: #333;
  line-height: 2vw;
}
.mod-main .mt:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.mod-main .mt .extra-r {
  float: right;
}
div.mod-coupon .mt .extra-r a {
  font-size: 1.4vw;
  font-family: "Microsoft YaHei";
}
a {
  text-decoration: none;
}
</style>
