<template>
  <main>
    <div class="mint-tabbar footer is-fixed">
      <a v-bind:class="{ 'mint-tab-item': true, 'is-selected': this.$props.selectOn === 'app'}" @click = 'goPage("app")'>
      <div class="mint-tab-item-icon">
        <img class = "index" src="../../assets/images/index1.png">
        <img class = "indexb" src="../../assets/images/index1b.png">
      </div>
      <div class="mint-tab-item-label"> 
        首页
      </div>
    </a>
    <a v-bind:class="{ 'mint-tab-item': true, 'is-selected': this.$props.selectOn === 'assistant'}" @click = 'goPage("assistant")'>
      <div class="mint-tab-item-icon">
        <img class = "index" src="../../assets/images/index4b.png">
        <img class = "indexb" src="../../assets/images/index4.png">
      </div>
      <div class="mint-tab-item-label"> 
      荟乐助教
      </div>
    </a>
    <a v-bind:class="{ 'mint-tab-item': true, 'is-selected': this.$props.selectOn === 'index2'}" @click = 'goPage("index2")'>
      <div class="mint-tab-item-icon">
        <img class = "index" src="../../assets/images/index2.png">
        <img class = "indexb" src="../../assets/images/index2b.png">
      </div>
      <div class="mint-tab-item-label"> 
      品类
      </div>
    </a>
    <a v-bind:class="{ 'mint-tab-item': true, 'is-selected': this.$props.selectOn === 'shopping'}" @click = 'goPage("shopping")'>
      <div class="mint-tab-item-icon">
       <img class = "index" src="../../assets/images/index3.png">
        <img class = "indexb" src="../../assets/images/index3b.png">
      </div>
      <div class="mint-tab-item-label">
      三天购
      </div>
    </a> 
    <!-- <a v-bind:class="{ 'mint-tab-item': true, 'is-selected': this.$props.selectOn === 'shoppingcart'}" @click = 'goPage("shoppingcart")'>
      <div class="mint-tab-item-icon">
        <img class = "index" src="../../assets/index4.png">
        <img class = "indexb" src="../../assets/index4b.png">
      </div>
      <div class="mint-tab-item-label">
      购物车
      </div>
    </a> -->
    <a v-bind:class="{ 'mint-tab-item': true, 'is-selected': this.$props.selectOn === 'mine'}" @click = 'goPage("mine")'>
      <div class="mint-tab-item-icon">
        <img class = "index" src="../../assets/images/index5.png">
        <img class = "indexb" src="../../assets/images/index5b.png">
      </div>
      <div class="mint-tab-item-label">
      我的
      </div>
    </a>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'PageFooter',
    props: ['selectOn'],
    methods: {
      goPage(page) {
        if (page === 'shopping') {
          this.$router.push({
            name: 'shoppinglist',
            query: {
              categoryId: 1
            }
          });
        } else {
          this.$emit('YOUR_EVENT_NAME');
          this.$router.push({
            name: page,
          });
        }
      },
    },
  };
</script>
<style scoped>
.mint-tab-item-label {
    color: inherit;
    font-size: 0.26rem;
    line-height: 1;
    color: #fff;
}
.is-selected .mint-tab-item-label {
  color: rgb(16,41,251);
}
</style>
